import React from "react"
import {navigate} from "@reach/router"

import './style.scss'

const ButtonBack = () => {
  const goBack = e => {
      e.preventDefault()
      navigate(-1)
  }

  return (
    <a href="/" onClick={goBack} className={`button-back`}>
     Voltar
    </a>
  )
}

export default ButtonBack
